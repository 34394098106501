
import SpecialSnoring from '../components/SpecialSnoring';
import { Helmet } from 'react-helmet';

 const SpecialSnoringPage = () =>{
    return(
     <>
      <Helmet>
            <title>Leczenie chrapania i bezdechu sennego Rzeszów | Mini Clinic</title>
            <meta name='description' content='Skuteczne leczenie chrapania i bezdechu sennego w Rzeszowie. Sprawdź nasze metody terapii ZOBS i umów się na konsultację już dziś!' />
        </Helmet>
        <SpecialSnoring />
       </>
    );
  }
  export default SpecialSnoringPage;
 